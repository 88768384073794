export const NumberAbbreviation = [
  "A",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "J",
  "Q",
  "K",
];

export const NumberName = [
  "Ace",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "Jack",
  "Queen",
  "King",
];

export const EmptyCardPath = "cards/E.svg";
export const DownCardPath = "cards/B2.svg";

export const Suits: Suit[] = ["Spades", "Hearts", "Diamonds", "Clubs"];

export type Suit = "Spades" | "Hearts" | "Diamonds" | "Clubs";

export type SuitColor = "Black" | "Red";

export type Direction = "Down" | "Up";

export interface Card {
  number: number;
  suit: Suit;
  direction: Direction;
}

export type StackType = "Cascade" | "Foundation";
export type CascadeDirection = "Horizontal" | "Vertical";

export interface Stack {
  cards: Card[];
  left: number;
  top: number;
  type: StackType;
  cascadeDirection: CascadeDirection;
  cascadeLimit?: number;
}

export interface Game {
  CardMargin: number;
  CascadeMargin: number;
  CardWidth: number;

  deck: Card[];
  moves: number;
  score: number;
  stacks: Stack[];
  time: number;

  click: (cards: Card[]) => void;
  stackClick: (stack: Stack) => void;
  clone: () => Game;
  canDrag: (cards: Card[], stack: Stack) => boolean;
  canDrop: (cards: Card[], stack: Stack) => boolean;
  handleGameUpdate: (onGameUpdate: GameUpdater) => void;
  move: (cards: Card[], stack: Stack) => void;
  undo?: () => void;
}

export type GameUpdater = () => void;
