import React, { useEffect, useState } from "react";
import "./App.css";
import { Solitaire } from "./Games/solitaire";
import { Freecell } from "./Games/freecell";
import { getCardsFromString, getCardString } from "./helpers";
import { Card, EmptyCardPath, Game, Stack, DownCardPath } from "./types";

import { getName, getSvgPath, getVirtualStack } from "./helpers";

export type CardLookup = {
  [key: string]: Card;
};

export type StackLookup = {
  [key: string]: { stack: Stack; index: number };
};

export interface RenderGameProps {
  game: Game;
}

export interface RenderStackProps {
  game: Game;
  stack: Stack;
}

export interface RenderCardProps {
  game: Game;
  index: number;
  stack: Stack;
  card?: Card;
  top?: string;
  cardLookup: CardLookup;
}

export function RenderCard({
  cardLookup,
  game,
  index,
  stack,
  card,
  top,
}: RenderCardProps) {
  const virtual = card
    ? stack.type === "Cascade"
      ? getVirtualStack(stack, card)
      : [card]
    : [];
  const adjustedIndex = stack.cascadeLimit
    ? Math.max(0, index + Math.min(0, stack.cascadeLimit - stack.cards.length))
    : index;
  //const adjustedIndex = 0;
  return (
    <img
      src={
        card
          ? card.direction === "Down"
            ? DownCardPath
            : getSvgPath(card)
          : EmptyCardPath
      }
      alt={card ? getName(card) : "Empty"}
      draggable={card && game.canDrag(virtual, stack)}
      onClick={() => {
        if (card) {
          game.click(virtual);
          return;
        }
        game.stackClick(stack);
      }}
      onDragStart={(e) => {
        if (!card) {
          return;
        }
        e.dataTransfer.dropEffect = "move";
        e.dataTransfer.setData("text/plain", getCardString(virtual));
        //const image = new Image();
        //image.src = getSvgPath(card);
        //image.width = game.CardWidth*window.innerWidth / 100;
        //e.dataTransfer.setDragImage(image, image.width/2, image.height/2);
      }}
      onDrop={(e) => {
        e.preventDefault();
        const virtual = getCardsFromString(
          e.dataTransfer.getData("text/plain")
        ).map((x) => cardLookup[JSON.stringify(x)]);
        game.move(virtual, stack);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        //e.dataTransfer.dropEffect = "move";
      }}
      style={{
        marginTop: `${
          stack.top +
          game.CascadeMargin *
            (stack.type === "Cascade" && stack.cascadeDirection === "Vertical"
              ? adjustedIndex
              : 0)
        }vw`,
        marginLeft: `${
          stack.left +
          game.CascadeMargin *
            (stack.type === "Cascade" && stack.cascadeDirection === "Horizontal"
              ? adjustedIndex
              : 0)
        }vw`,
        position: "fixed",
        width: `${game.CardWidth}vw`,
        zIndex: index,
        transition: "all .2s ease-in-out",
        border: card?.direction === 'Down' ? "1px solid white" : "none",
        borderRadius: card?.direction === 'Down' ? "10px" : "none",
        //boxShadow: "6px 6px 10px rgba(0,0,0,0.2)",
      }}
    />
  );
}

export function Time({ game }: RenderGameProps) {
  const [time, setTime] = useState(game.time);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(game.time);
    }, 1000);

    function clear() {
      clearInterval(interval);
    }

    return clear;
  });
  return (
    <>
      {String(Math.floor(time / 60)).padStart(2, "0")}:
      {String(time % 60).padStart(2, "0")}
    </>
  );
}

export function RenderGame({ game }: RenderGameProps) {
  const cardLookup: CardLookup = {};
  const stackLookup: StackLookup = {};
  for (const card of game.deck) {
    cardLookup[JSON.stringify(card)] = card;
  }
  game.stacks.forEach((stack) =>
    stack.cards.forEach(
      (card, index) => (stackLookup[JSON.stringify(card)] = { stack, index })
    )
  );

  return (
    <div style={{ position: "fixed" }}>
      {game.stacks.map((stack, index) => (
        <RenderCard
          key={index}
          game={game}
          stack={stack}
          cardLookup={cardLookup}
          index={0}
        />
      ))}
      {game.deck
        .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)))
        .map((card) => {
          const { index, stack } = stackLookup[JSON.stringify(card)];
          return (
            <RenderCard
              key={card ? getSvgPath(card) : EmptyCardPath}
              game={game}
              stack={stack}
              card={card}
              cardLookup={cardLookup}
              index={index}
            />
          );
        })}
    </div>
  );
}

function App() {
  const [game, setGame] = useState<Game>(new Solitaire());
  useEffect(() => {
    function handleGameUpdate() {
      setGame(game.clone());
    }

    game.handleGameUpdate(handleGameUpdate);
  }, [game]);

  return (
    <div className="App">
      <div style={{ float: "left", width: "100px", padding: "15px" }}>
        <div>
          <button
            style={{ marginBottom: "5px", textAlign: "center", width: "100%" }}
            onClick={() => setGame(new Freecell())}
          >
            New Freecell
          </button>
        </div>
        <div>
          <button
            style={{ marginBottom: "5px", textAlign: "center", width: "100%" }}
            onClick={() => setGame(new Solitaire())}
          >
            New Solitaire
          </button>
        </div>
        <div
          style={{ marginBottom: "5px", textAlign: "center", color: "white" }}
        >
          <Time game={game} />
        </div>
        <div
          style={{ marginBottom: "5px", textAlign: "center", color: "white" }}
        >
          Score: {game.score}
        </div>
        <div
          style={{ marginBottom: "5px", textAlign: "center", color: "white" }}
        >
          Moves: {game.moves}
        </div>
        <div>
          <button
            style={{ marginBottom: "5px", textAlign: "center", width: "100%" }}
            onClick={() => game.undo && game.undo()}
          >
            Undo
          </button>
        </div>
      </div>
      <div style={{ display: "inline-block" }}>
        <RenderGame game={game} />
      </div>
    </div>
  );
}

export default App;
