import {
  Card,
  CascadeDirection,
  Game,
  GameUpdater,
  Stack,
  StackType,
} from "../types";

import { getDeck, getSuitColor, shuffleDeck } from "../helpers";

const CARD_MARGIN = 2.5;
const CASCADE_MARGIN = 1.7;
const CARD_WIDTH = 8;

const TOP_CASCADE_MARGIN = 15;
const CARD_CASCADE_MARGIN = 2.95;
const LEFT_BUILD_MARGIN = 45;

function canCascade(top: Card, bottom: Card): boolean {
  return (
    getSuitColor(top.suit) !== getSuitColor(bottom.suit) &&
    top.number - 1 === bottom.number
  );
}

function canBuild(build: Stack, cards: Card[]): boolean {
  if (cards.length > 1) {
    return false;
  }
  const card = cards[0];
  if (!build.cards.length) {
    return card.number === 0;
  }

  const top = build.cards[build.cards.length - 1];
  return top.suit === card.suit && top.number + 1 === card.number;
}

class BaseStack {
  public cards: Card[] = [];
  public left: number = 0;
  public top: number = 0;
  public type: StackType = "Foundation";
  public cascadeDirection: CascadeDirection = "Vertical";

  public remove(cards: Card[]) {
    this.cards = this.cards.filter((target) => cards.indexOf(target) < 0);
  }

  public add(cards: Card[]) {
    this.cards = this.cards.concat(cards);
  }

  public canDrag(cards: Card[]) {
    return false;
  }

  public canDrop(cards: Card[], maxCardMoves: number) {
    return false;
  }
}

class CascadeStack extends BaseStack {
  constructor(index: number) {
    super();
    this.type = "Cascade";
    this.top = TOP_CASCADE_MARGIN;
    this.left = (CARD_WIDTH + CARD_CASCADE_MARGIN) * index;
  }

  public canDrop(cards: Card[], maxCardMoves: number) {
    if (cards.length === 1 && !this.cards.length) {
      return true;
    }

    const bottom = this.cards[this.cards.length - 1];
    console.log(bottom);
    if (bottom && !canCascade(bottom, cards[0])) {
      return false;
    }

    return cards.length <= maxCardMoves;
  }

  public canDrag(cards: Card[]) {
    let currentIndex = 0;
    while (currentIndex < cards.length - 1) {
      if (!canCascade(cards[currentIndex], cards[currentIndex + 1])) {
        return false;
      }
      currentIndex++;
    }
    return true;
  }
}

class FreecellStack extends BaseStack {
  constructor(index: number) {
    super();
    this.left = (CARD_WIDTH + CARD_MARGIN) * index;
  }

  public canDrop(cards: Card[], maxCardMoves: number) {
    return !this.cards.length && cards.length === 1;
  }

  public canDrag(cards: Card[]) {
    return true;
  }
}

class BuildStack extends BaseStack {
  constructor(index: number) {
    super();
    this.left = (CARD_WIDTH + CARD_MARGIN) * index + LEFT_BUILD_MARGIN;
  }

  public canDrop(cards: Card[], maxCardMoves: number): boolean {
    return canBuild(this, cards);
  }

  public canDrag(cards: Card[]) {
    return false;
  }
}

type FreecellStackTypes = FreecellStack | CascadeStack | BuildStack;

export class Freecell implements Game {
  public deck: Card[];
  public CardMargin = CARD_MARGIN;
  public CascadeMargin = CASCADE_MARGIN;
  public CardWidth = CARD_WIDTH;
  public stacks: FreecellStackTypes[] = [];

  private cascades: CascadeStack[] = [];
  private freecells: FreecellStack[] = [];
  private builds: BuildStack[] = [];
  private updaters: GameUpdater[] = [];
  private _moves = 0;
  private _startTime: Date;
  private _endTime?: Date;

  constructor() {
    this.cascades = Array.from(Array(8).keys()).map(
      (index) => new CascadeStack(index)
    );
    this.freecells = Array.from(Array(4).keys()).map(
      (index) => new FreecellStack(index)
    );
    this.builds = Array.from(Array(4).keys()).map(
      (index) => new BuildStack(index)
    );
    this.stacks = this.stacks.concat(
      this.cascades,
      this.freecells,
      this.builds
    );

    let lcv = 0;
    this.deck = shuffleDeck(getDeck()).map((c) => ({ ...c, direction: "Up" }));

    this.deck.forEach((card) => {
      this.cascades[lcv].cards.push(card);
      lcv = (lcv + 1) % this.cascades.length;
    });

    this._startTime = new Date();
  }

  get moves(): number {
    return this._moves;
  }

  get score(): number {
    let score = 0;
    for (let build of this.builds) {
      score += build.cards.length * 5;
    }
    return score;
  }

  get time(): number {
    const currentTime = this._endTime ?? new Date();
    return Math.round((Number(currentTime) - Number(this._startTime)) / 1000);
  }

  private findCurrentStack(cards: Card[]): FreecellStackTypes | undefined {
    return this.stacks.find((stack) => stack.cards.indexOf(cards[0]) >= 0);
  }

  public canDrop(cards: Card[], stack: Stack): boolean {
    return (stack as FreecellStackTypes).canDrop(
      cards,
      this.getMaxCardMoves(stack)
    );
  }

  public canDrag(cards: Card[], stack: Stack) {
    return (stack as FreecellStackTypes).canDrag(cards);
  }

  public move(cards: Card[], stack: Stack) {
    if (!this.canDrop(cards, stack)) {
      return;
    }

    const existingStack = this.findCurrentStack(cards);
    existingStack && existingStack.remove(cards);
    (stack as FreecellStackTypes).add(cards);
    this._moves += cards.length;
    this.automove();
    if (this.builds.reduce((a, b) => b.cards.length + a, 0) === 52) {
      this.end();
    }
    this.updaters.forEach((updater) => updater());
  }

  public handleGameUpdate(onGameUpdate: GameUpdater) {
    this.updaters.push(onGameUpdate);
  }

  public stackClick(stack: Stack) {}

  public click(cards: Card[]) {
    if (!this.clickable(cards)) {
      return false;
    }

    for (const build of this.builds) {
      if (this.canDrop(cards, build)) {
        this.move(cards, build);
        return;
      }
    }

    for (const cascade of this.cascades) {
      if (cascade.cards.length && this.canDrop(cards, cascade)) {
        this.move(cards, cascade);
        return;
      }
    }

    for (const cascade of this.cascades) {
      if (!cascade.cards.length && this.canDrop(cards, cascade)) {
        this.move(cards, cascade);
        return;
      }
    }

    for (const freecell of this.freecells) {
      if (this.canDrop(cards, freecell)) {
        this.move(cards, freecell);
        return;
      }
    }
  }

  public clone(): Game {
    const clone = new Freecell();
    Object.assign(clone, this);
    return clone;
  }

  private end() {
    if (this._endTime) {
      return;
    }

    this._endTime = new Date();
  }

  private clickable(cards: Card[]) {
    for (const cascade of this.cascades) {
      if (this.canDrag(cards, cascade)) {
        return true;
      }
    }

    for (const freecell of this.freecells) {
      if (this.canDrag(cards, freecell)) {
        return true;
      }
    }

    return false;
  }

  private getMaxCardMoves(exclude: Stack): number {
    const emptyFreecells = this.freecells.filter(
      (stack) => stack.cards.length === 0
    ).length;
    const emptyCascades = this.cascades
      .filter((stack) => stack !== exclude)
      .filter((stack) => stack.cards.length === 0).length;
    return Math.pow(2, emptyCascades) * (1 + emptyFreecells);
  }

  private automove() {
    const hasNoCascadeableCards = (card: Card) => {
      for (const cascade of this.cascades) {
        for (const target of cascade.cards) {
          if (canCascade(card, target)) {
            return false;
          }
        }
      }
      for (const freecell of this.freecells) {
        for (const target of freecell.cards) {
          if (canCascade(card, target)) {
            return false;
          }
        }
      }
      return true;
    };

    for (const stack of ([] as FreecellStackTypes[]).concat(
      this.cascades,
      this.freecells
    )) {
      const bottom = stack.cards[stack.cards.length - 1];
      if (bottom && hasNoCascadeableCards(bottom)) {
        for (const build of this.builds) {
          if (canBuild(build, [bottom])) {
            this.move([bottom], build);
            return;
          }
        }
      }
    }
  }
}

export function getGame(): Game {
  return new Freecell();
}
