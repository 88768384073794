import {
  Card,
  NumberAbbreviation,
  NumberName,
  Stack,
  Suit,
  SuitColor,
  Suits,
} from "./types";

export function getCardString(cards: Card[]): string {
  return JSON.stringify(cards);
}

export function getCardsFromString(str: string): Card[] {
  return JSON.parse(str);
}

export function getVirtualStack(stack: Stack, card: Card): Card[] {
  const index = stack.cards.indexOf(card);
  if (index === stack.cards.length - 1) {
    return [card];
  }
  return stack.cards.slice(index, stack.cards.length);
}

export function getCardCode(card: Card): string {
  return `${NumberAbbreviation[card.number]}${card.suit[0]}`;
}

export function getSuitColor(suit: Suit): SuitColor {
  switch (suit) {
    case "Clubs":
      return "Black";
    case "Spades":
      return "Black";
    case "Hearts":
      return "Red";
    case "Diamonds":
      return "Red";
  }
}

export function getSvgPath(card: Card): string {
  return `cards/${getCardCode(card)}.svg`;
}

export function getName(card: Card): string {
  return `${NumberName[card.number]} of ${card.suit}`;
}

export function getDeck(): Card[] {
  const cards: Card[] = [];
  NumberAbbreviation.forEach((number, index) => {
    Suits.forEach((suit) => {
      cards.push({
        number: index,
        suit,
        direction: "Down",
      });
    });
  });
  return cards;
}

// TODO: Make this true random
export function getRandomCard(cards: Card[]) {
  const index = Math.floor(Math.random() * cards.length);
  return cards[index];
}

// TODO: Make this true random
export function pullRandomCard(cards: Card[]) {
  const index = Math.floor(Math.random() * cards.length);
  const card = cards[index];
  cards.splice(index, 1);
  return card;
}

export function shuffleDeck(cards: Card[]) {
  const shuffled: Card[] = [];
  const unshuffled = cards.splice(0);
  while (unshuffled.length) {
    shuffled.push(pullRandomCard(unshuffled));
  }
  return shuffled;
}
