import {
  Card,
  CascadeDirection,
  Direction,
  Game,
  GameUpdater,
  Stack,
  StackType,
} from "../types";

import { getDeck, getSuitColor, shuffleDeck } from "../helpers";

const CARD_MARGIN = 3;
const CASCADE_MARGIN = 1.7;
const CARD_WIDTH = 8;

const TOP_CASCADE_MARGIN = 15;
const CARD_CASCADE_MARGIN = 5;
const LEFT_BUILD_MARGIN = 45;

function canCascade(top: Card, bottom: Card): boolean {
  return (
    getSuitColor(top.suit) !== getSuitColor(bottom.suit) &&
    top.number - 1 === bottom.number
  );
}

function canBuild(build: Stack, cards: Card[]): boolean {
  if (cards.length > 1) {
    return false;
  }
  const card = cards[0];
  if (!build.cards.length) {
    return card.number === 0;
  }

  const top = build.cards[build.cards.length - 1];
  return top.suit === card.suit && top.number + 1 === card.number;
}

class BaseStack {
  public cards: Card[] = [];
  public left: number = 0;
  public top: number = 0;
  public type: StackType = "Foundation";
  public cascadeDirection: CascadeDirection = "Vertical";
  public cascadeLimit: number | undefined = undefined;

  public remove(cards: Card[]) {
    this.cards = this.cards.filter((target) => cards.indexOf(target) < 0);
  }

  public add(cards: Card[]) {
    this.cards = this.cards.concat(cards);
  }

  public canDrag(cards: Card[]) {
    return false;
  }

  public canDrop(cards: Card[], maxCardMoves: number) {
    return false;
  }
}

class CascadeStack extends BaseStack {
  constructor(index: number) {
    super();
    this.type = "Cascade";
    this.top = TOP_CASCADE_MARGIN;
    this.left = (CARD_WIDTH + CARD_CASCADE_MARGIN) * index;
  }

  public canDrop(cards: Card[], maxCardMoves: number) {
    if (cards.length === 1 && !this.cards.length) {
      return cards[0].number === 12;
    }

    const bottom = this.cards[this.cards.length - 1];
    if (bottom && !canCascade(bottom, cards[0])) {
      return false;
    }

    return cards.length <= maxCardMoves;
  }

  public canDrag(cards: Card[]) {
    let currentIndex = 0;
    while (currentIndex < cards.length - 1) {
      if (!canCascade(cards[currentIndex], cards[currentIndex + 1])) {
        return false;
      }
      currentIndex++;
    }
    return true;
  }
}

class ReserveStack extends BaseStack {
  public canDrop() {
    return false;
  }

  public canDrag() {
    return false;
  }
}

class TableauStack extends BaseStack {
  constructor() {
    super();
    this.left = CARD_WIDTH + CARD_MARGIN;
    this.cascadeLimit = 3;
    this.type = "Cascade";
    this.cascadeDirection = "Horizontal";
  }

  public canDrop() {
    return false;
  }

  public canDrag() {
    return true;
  }
}

class BuildStack extends BaseStack {
  constructor(index: number) {
    super();
    this.left = (CARD_WIDTH + CARD_MARGIN) * index + LEFT_BUILD_MARGIN;
  }

  public canDrop(cards: Card[], maxCardMoves: number): boolean {
    return canBuild(this, cards);
  }

  public canDrag(cards: Card[]) {
    return false;
  }
}

type SolitaireStackTypes =
  | ReserveStack
  | CascadeStack
  | BuildStack
  | TableauStack;
const CascadeCounts = [1, 2, 3, 4, 5, 6, 7];

export class Solitaire implements Game {
  public deck: Card[];
  public CardMargin = CARD_MARGIN;
  public CascadeMargin = CASCADE_MARGIN;
  public CardWidth = CARD_WIDTH;
  public stacks: SolitaireStackTypes[] = [];

  private cascades: CascadeStack[] = [];
  private reserve: ReserveStack;
  private tableau: TableauStack;
  private builds: BuildStack[] = [];
  private updaters: GameUpdater[] = [];
  private _moves = 0;
  private _startTime: Date;
  private _endTime?: Date;

  constructor() {
    this.cascades = Array.from(Array(CascadeCounts.length).keys()).map(
      (index) => new CascadeStack(index)
    );
    this.reserve = new ReserveStack();
    this.tableau = new TableauStack();
    this.builds = Array.from(Array(4).keys()).map(
      (index) => new BuildStack(index)
    );
    this.stacks = this.stacks.concat(
      this.cascades,
      [this.reserve, this.tableau],
      this.builds
    );

    this.deck = shuffleDeck(getDeck());

    let cardIndex = 0;
    for (
      let cascadeIndex = 0;
      cascadeIndex < CascadeCounts.length;
      cascadeIndex++
    ) {
      for (let lcv = 0; lcv < CascadeCounts[cascadeIndex]; lcv++) {
        const card = this.deck[cardIndex];
        this.cascades[cascadeIndex].cards.push(card);
        cardIndex++;
      }
    }

    this.stackPeek();

    for (true; cardIndex < this.deck.length; cardIndex++) {
      this.reserve.cards.push(this.deck[cardIndex]);
    }

    this._startTime = new Date();
  }

  get moves(): number {
    return this._moves;
  }

  get score(): number {
    let score = 0;
    for (let build of this.builds) {
      score += build.cards.length * 5;
    }
    return score;
  }

  get time(): number {
    const currentTime = this._endTime ?? new Date();
    return Math.round((Number(currentTime) - Number(this._startTime)) / 1000);
  }

  private findCurrentStack(cards: Card[]): SolitaireStackTypes | undefined {
    return this.stacks.find((stack) => stack.cards.indexOf(cards[0]) >= 0);
  }

  public canDrop(cards: Card[], stack: Stack): boolean {
    return (stack as SolitaireStackTypes).canDrop(
      cards,
      this.getMaxCardMoves()
    );
  }

  public canDrag(cards: Card[], stack: Stack) {
    return (stack as SolitaireStackTypes).canDrag(cards);
  }

  public move(
    cards: Card[],
    stack: Stack,
    skipDropCheck?: boolean,
    directionOverride?: Direction
  ) {
    if (!skipDropCheck && !this.canDrop(cards, stack)) {
      return;
    }

    const existingStack = this.findCurrentStack(cards);
    existingStack && existingStack.remove(cards);
    (stack as SolitaireStackTypes).add(cards);
    this._moves += cards.length;
    directionOverride &&
      cards.forEach((x) => (x.direction = directionOverride));
    this.automove();
    if (this.builds.reduce((a, b) => b.cards.length + a, 0) === 52) {
      this.end();
    }
    this.stackPeek();
    this.updaters.forEach((updater) => updater());
  }

  public handleGameUpdate(onGameUpdate: GameUpdater) {
    this.updaters.push(onGameUpdate);
  }

  public stackClick(stack: Stack) {
    if (stack !== this.reserve || this.reserve.cards.length > 0) {
      return;
    }
    this.tableau.cards
      .reverse()
      .forEach((card) => this.move([card], this.reserve, true, "Down"));
  }

  public click(cards: Card[]) {
    if (!this.clickable(cards)) {
      return false;
    }

    if (this.reserve.cards.indexOf(cards[0]) >= 0) {
      this.fanTableau();
      return;
    }

    for (const build of this.builds) {
      if (this.canDrop(cards, build)) {
        this.move(cards, build);
        return;
      }
    }

    for (const cascade of this.cascades) {
      if (cascade.cards.length && this.canDrop(cards, cascade)) {
        this.move(cards, cascade);
        return;
      }
    }

    for (const cascade of this.cascades) {
      if (!cascade.cards.length && this.canDrop(cards, cascade)) {
        this.move(cards, cascade);
        return;
      }
    }
  }

  public clone(): Game {
    const clone = new Solitaire();
    Object.assign(clone, this);
    return clone;
  }

  private fanTableau() {
    if (!this.reserve.cards.length) {
      return;
    }

    const size = Math.min(3, this.reserve.cards.length);
    const cards = this.reserve.cards.slice(this.reserve.cards.length - size);
    this.move(cards, this.tableau, true, "Up");
  }

  private stackPeek() {
    for (const cascade of this.cascades) {
      const card = cascade.cards[cascade.cards.length - 1];
      if (!card) {
        continue;
      }
      card.direction = "Up";
    }
    for (const build of this.builds) {
      build.cards.forEach((x) => (x.direction = "Up"));
    }
  }

  private end() {
    if (this._endTime) {
      return;
    }

    this._endTime = new Date();
  }

  private clickable(cards: Card[]) {
    for (const cascade of this.cascades) {
      if (this.canDrag(cards, cascade)) {
        return true;
      }
    }

    if (this.reserve.cards.indexOf(cards[0]) >= 0) {
      return true;
    }

    return false;
  }

  private getMaxCardMoves(): number {
    return 52;
  }

  private automove() {
    const hasNoCascadeableCards = (card: Card) => {
      for (const cascade of this.cascades) {
        for (const target of cascade.cards) {
          if (canCascade(card, target)) {
            return false;
          }
        }
      }
      return true;
    };

    for (const stack of ([] as SolitaireStackTypes[]).concat(this.cascades)) {
      const bottom = stack.cards[stack.cards.length - 1];
      if (bottom && hasNoCascadeableCards(bottom)) {
        for (const build of this.builds) {
          if (canBuild(build, [bottom])) {
            this.move([bottom], build);
            return;
          }
        }
      }
    }
  }
}

export function getGame(): Game {
  return new Solitaire();
}
